











































































































































































































































































.box-saldo {
  color: #FFF;
  &-titulo {
    text-align: center;
    font-size: .9rem;
    width: 100%;
    display: block;
    padding-top: .4rem;
  }

  &__content {
    text-align: center;
   span {
     color: #Fff;
     display: block;
   }

   strong {
     color: #Fff;
     font-size: 1.3rem;
   }
  }

  .date {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 10px;
  }
}
